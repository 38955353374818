import { DateTime } from 'luxon';
export function getISOFormattedDate(date) {
    if (!date) {
        return '';
    }
    return DateTime.fromISO(date).toSQLDate();
}
export const formatDateForAPI = (date) => {
    if (!date) {
        return '';
    }
    const newDate = DateTime.fromISO(date.toISOString()).toString();
    return newDate.substring(0, 16);
};
